<template>
    <div class="CodeDuplicationCheck">
        <el-row class="ButtonSet">
            <el-button type="primary" :disabled="single" @click="SubmitCreateCheckResult(ids)"
                icon="el-icon-s-check">生成查重结果</el-button>
            <el-button type="primary" :disabled="single" @click="GetSearchWork(ids)"
                icon="el-icon-tickets">查看作业列表</el-button>
            <el-button type="primary" :disabled="single" @click="GetSearchCheckResult(ids)"
                icon="el-icon-document-checked">查重结果列表</el-button>
            <el-button type="primary" :disabled="single" @click="GetDownloadCheckResult(ids)"
                icon="el-icon-upload">下载结果列表</el-button>
            <span>*提示：请勾选一下比赛进行功能操作</span>
        </el-row>
        <el-table ref="MatchListTable" :data="MatchList" tooltip-effect="dark" border class="single-select-table"
            style="width: 100%" @selection-change="handleSelectionChange" @row-click="SingleRowChange">
            <el-table-column align="center" type="selection" width="55">
            </el-table-column>
            <el-table-column prop="challenge_id" label="比赛编号" width="80"></el-table-column>
            <el-table-column prop="challenge_title" label="比赛名称"></el-table-column>
            <el-table-column prop="registration_time" label="比赛报名时间" width="200"></el-table-column>
            <el-table-column prop="start_time" label="比赛起始时间" width="200"></el-table-column>
            <el-table-column prop="en_time" label="比赛终止时间" width="200"></el-table-column>
            <el-table-column prop="challenge_status" label="状态" width="120">
                <template slot-scope="scope">
                    {{ scope.row.challenge_status == 0 ? "进行中" : "已结束" }}
                </template>
            </el-table-column>
        </el-table>
        <pagination :total="MatchTotal" :page.sync="MatchParams.pageNum" :limit.sync="MatchParams.pageSize"
            @pagination="getsearchMatchList" />

        <!-- 查看作业列表 -->
        <el-dialog :close-on-click-modal="false" :title="PopUpTitle" :visible.sync="SearchWorkdialogVisible" width="90%">
            <el-table :data="SearchWorkList" style="width: 100%">
                <!-- <el-table-column prop="challenge_id" label="比赛ID" width="80"></el-table-column> -->
                <el-table-column prop="id" label="作业ID"></el-table-column>
                <el-table-column prop="job_status" label="生成作业状态">
                    <template slot-scope="scope">
                        {{ scope.row.job_status == 'succeed' ? "成功" : scope.row.job_status == 'alive' ? "进行中" :'失败' }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="job_log" label="生成作业描述"></el-table-column> -->
                <el-table-column prop="create_time" label="生成时间"></el-table-column>
                <el-table-column prop="update_time" label="更新时间"></el-table-column>
            </el-table>
            <pagination :total="SearchWorktotal" :page.sync="SearchWorkParams.pageNum"
                :limit.sync="SearchWorkParams.pageSize" @pagination="GetSearchWork" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="ClosePopUp">关 闭</el-button>
            </span>
        </el-dialog>

        <el-dialog :close-on-click-modal="false" :title="PopUpTitle" :visible.sync="SearchCheckResultdialogVisible"
            width="90%">
            <el-table :data="SearchCheckResultList" style="width: 100%">
                <!-- <el-table-column prop="challenge_id" label="比赛ID"></el-table-column> -->

                <el-table-column prop="nickname" label="昵称"></el-table-column>
                <el-table-column prop="realname" label="真实姓名"></el-table-column>
                <el-table-column prop="username" label="手机号"></el-table-column>
                <!-- <el-table-column prop="school_name" label="学校"></el-table-column> -->
                <el-table-column prop="student_num" label="学号"></el-table-column>
                <!-- <el-table-column prop="specialty" label="专业"></el-table-column> -->
                <!-- <el-table-column prop="student_card_path" label="学生证"></el-table-column> -->
                <!-- <el-table-column prop="education_background" label="学历"></el-table-column> -->
                <!-- <el-table-column prop="faculty_adviser" label="指导老师"></el-table-column> -->
                <!-- <el-table-column prop="grade" label="年级"></el-table-column> -->
                <!-- <el-table-column prop="email" label="Email"></el-table-column> -->
                <el-table-column prop="code_cols" label="代码行数"></el-table-column>
                <el-table-column prop="max_sim" label="最大相似度">
                    <template slot-scope="scope">
                        {{ (scope.row.max_sim * 100).toFixed(2) }}%
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="max_sim_user" label="与谁最相似"></el-table-column> -->
                <el-table-column prop="max_sim_user" label="相似手机号"></el-table-column>
                <el-table-column prop="mean_sim" label="平均相似度">
                    <template slot-scope="scope">
                        {{ (scope.row.mean_sim * 100).toFixed(2) }}%
                    </template>
                </el-table-column>
                <el-table-column prop="min_sim" label="最小相似度">
                    <template slot-scope="scope">
                        {{ (scope.row.min_sim * 100).toFixed(2) }}%
                    </template>
                </el-table-column>
            </el-table>
            <pagination :total="SearchCheckResulttotal" :page.sync="SearchCheckResultParams.pageNum"
                :limit.sync="SearchCheckResultParams.pageSize" @pagination="GetSearchCheckResult" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="ClosePopUp">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {
    searchMatchList,
    create_check_result,
    search_work,
    search_check_result,
} from "@/api/ManagementCenter.js";
import {
    publicbaseURLs
} from "@/utils/request";
import {
    getToken,
} from "@/utils/auth";
import axios from "axios";
export default {
    data() {
        return {
            MatchParams: {
                keywords: "",
                pageNum: 1,
                pageSize: 10,
            },
            ids: '',
            single: true,
            MatchList: [],
            MatchTotal: 0,
            SearchWorkParams: {
                keywords: "",
                challenge_id: '',
                pageNum: 1,
                pageSize: 10,
            },
            SearchWorkList: [],
            SearchWorktotal: 0,
            SearchWorkdialogVisible: false,
            SearchCheckResultParams: {
                challenge_id: "",
                pageNum: 1,
                pageSize: 10,
            },
            SearchCheckResultList: [],
            SearchCheckResulttotal: 0,
            SearchCheckResultdialogVisible: false,
            PopUpTitle: '',
            DownloadApi: publicbaseURLs() + "/download_check_result",
        };
    },
    mounted() {
        this.getsearchMatchList();
    },
    methods: {
        // 行复选框选择数据行
        handleSelectionChange(selection) {
            console.log(1,'selection')
            if (selection.length > 1) {
                console.log(selection,'selection')
                this.$refs.MatchListTable.clearSelection()
                this.$refs.MatchListTable.toggleRowSelection(selection.pop())
                return
            }
            if (selection.length == 1) {
                this.ids = selection[0].challenge_id;
                this.single = selection.length != 1;
                return
            } else {
                this.ids = '';
                this.single = true;
            }
        },
        // 表格行列表选择
        SingleRowChange(row) {
            console.log(row.challenge_id,'row')
            if (this.ids == row.challenge_id) {
                this.$refs.MatchListTable.clearSelection()
                return
            }
            this.$refs.MatchListTable.clearSelection()
            this.$refs.MatchListTable.toggleRowSelection(row);
            this.SearchWorkParams.challenge_id = row.challenge_id
            this.SearchCheckResultParams.challenge_id = row.challenge_id
        },
        // 关闭弹窗
        ClosePopUp() {
            this.SearchWorkList = []
            this.SearchWorktotal = 0
            this.SearchWorkdialogVisible = false
            this.SearchCheckResultList = []
            this.SearchCheckResulttotal = 0
            this.SearchCheckResultdialogVisible = false
            this.PopUpTitle = ''
        },
        // 获取比赛列表
        getsearchMatchList() {
            // 默认清楚选择框状态
            this.$refs.MatchListTable.clearSelection()
            this.ids = '';
            this.single = true;
            searchMatchList(this.MatchParams).then((res) => {
                this.MatchList = res.data.list;
                this.MatchTotal = res.data.total;
            });
        },
        // 生成查重结果
        SubmitCreateCheckResult(challenge_id) {
            create_check_result({
                challenge_id
            }).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '生成查重结果' + res.msg,
                        type: 'success'
                    });
                }
            })
        },
        // 查看作业列表（比赛）
        GetSearchWork(id) {
            this.SearchWorkParams.challenge_id = this.SearchWorkParams.challenge_id || id
            search_work(this.SearchWorkParams).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '生成查看作业列表' + res.msg,
                        type: 'success'
                    });
                    this.PopUpTitle = '查看作业列表'
                    this.SearchWorkdialogVisible = true
                    this.SearchWorkList = res.data.list
                    this.SearchWorktotal = res.data.total
                }
            })
        },
        // 查重结果列表（比赛）
        GetSearchCheckResult(id) {
            this.SearchCheckResultParams.challenge_id = this.SearchCheckResultParams.challenge_id ||id
            search_check_result(this.SearchCheckResultParams).then(res => {
                if (res.code == 200) {
                    // this.$message({
                    //     message: '生成查重结果列表' + res.msg,
                    //     type: 'success'
                    // });
                    this.PopUpTitle = '查重结果列表'
                    this.SearchCheckResultdialogVisible = true
                    this.SearchCheckResultList = res.data.list
                    this.SearchCheckResulttotal = res.data.total
                }
            })
        },
        // 下载结果列表（比赛）
        GetDownloadCheckResult(id) {
            this.SearchCheckResultParams.challenge_id = id
            let DownloadCheckResult = JSON.parse(JSON.stringify(this.SearchCheckResultParams))
            DownloadCheckResult.paging = '0'
            axios({
                // responseType: 'blob'
                url: this.DownloadApi,
                method: "post",
                data: DownloadCheckResult,
                responseType: "blob",
                headers: {
                    Authorization: getToken(),
                    // "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                //这里res.data是返回的blob对象,即对应的二进制流
                let blob = res.data;
                let downloadElement = document.createElement("a");
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                let fileName = res.headers["content-disposition"]
                    ? res.headers["content-disposition"]
                        .split(";")[1]
                        .split("=")[1]
                    : new Date().getTime() + ".xlsx";

                downloadElement.href = href;
                downloadElement.download = decodeURIComponent(fileName); //解码
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href); //释放掉blob对象
            });
        }
    },
};
</script>
<style lang="scss" scoped>
.CodeDuplicationCheck {
    .ButtonSet {
        box-sizing: border-box;

        span {
            padding-left: 10px;
            color: red;
        }
    }

    .single-select-table {
        margin-top: 20px;
    }

    ::v-deep .single-select-table thead .el-table-column--selection .cell {
        display: none;
    }

    table {
        font-size: 14px;
        width: 100%;
        background: #fff;
        margin: 1em 0;
        border: 1px solid rgba(34, 36, 38, 0.15);
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0.28571429rem;
        text-align: left;
        color: rgba(0, 0, 0, 0.87);
        border-collapse: separate;
        border-spacing: 0;

        thead {
            box-shadow: none;

            tr {
                th {
                    cursor: auto;
                    background: #f9fafb;
                    text-align: inherit;
                    color: rgba(0, 0, 0, 0.87);
                    padding: 0.92857143em 0.78571429em;
                    vertical-align: inherit;
                    font-style: none;
                    font-weight: 700;
                    text-transform: none;
                    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
                    border-left: 1px solid rgba(34, 36, 38, 0.1);
                }

                th:last-child {
                    width: 200px;
                }
            }
        }

        tbody {
            tr {
                font-size: 13px;

                td {
                    padding: 0.78571429em 0.78571429em;
                    text-align: inherit;
                    border-left: 1px solid rgba(34, 36, 38, 0.1);
                    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
                }

                td:last-child {
                    width: 200px;

                    i {
                        cursor: pointer;
                        padding-left: 14px;
                        font-size: 18px;
                        float: left;
                        color: rgb(24, 144, 255);
                    }
                }
            }
        }
    }
}
</style>