<template>
  <div class="Examine">
    <el-row>
      <el-col :span="8">
        <el-select style="width: 100%" v-model="ExamineParams.challenge_id" clearable placeholder="请选择审核比赛人员名单">
          <el-option v-for="item in ExamineMatchList" :key="item.challenge_id" :label="item.challenge_title"
            :value="item.challenge_id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" style="padding-left: 10px">
        <el-input v-model="ExamineParams.keywords" @keyup.enter.native="ExamineSearch" clearable
          placeholder="请输入用户关键字"></el-input>
      </el-col>
      <el-col :span="10" style="padding-left: 10px">
        <el-button type="primary" @click="ExamineSearch">搜索</el-button>
        <el-button type="primary" @click="DownloadApprovedList" v-if="ReviewedIndex == 2">下载已审核人员名单</el-button>
      </el-col>
    </el-row>
    <table>
      <thead>
        <tr>
          <th>姓名</th>
          <th v-if="ReviewedIndex == 2">昵称</th>
          <th>学号</th>
          <th>学校</th>
          <th v-if="ReviewedIndex == 1">专业</th>
          <th v-if="ReviewedIndex == 1">年级</th>
          <th v-if="ReviewedIndex == 1">学历</th>
          <th>手机号</th>
          <th v-if="ReviewedIndex == 1">报名时间</th>
          <th v-if="ReviewedIndex == 1" style="width: 80px; text-align: center">操作</th>
          <!-- <th>排位分</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in ExamineDataList" :key="index">
          <td>{{ item.realname }}</td>
          <td v-if="ReviewedIndex == 2">{{ item.nickname }}</td>
          <td>{{ item.student_num }}</td>
          <td>{{ item.school_name }}</td>
          <td v-if="ReviewedIndex == 1">{{ item.specialty }}</td>
          <td v-if="ReviewedIndex == 1">{{ item.grade }}</td>
          <td v-if="ReviewedIndex == 1">{{ item.education_background }}</td>
          <td>{{ item.username }}</td>
          <td v-if="ReviewedIndex == 1">{{ item.update_time }}</td>
          <td v-if="ReviewedIndex == 1">
            <i style="
                cursor: pointer;
                padding-left: 20px;
                font-size: 18px;
                float: left;
                color: rgb(24, 144, 255);
              " title="审核详情" class="el-icon-s-check" @click="ExamineDetails(item)"></i>
          </td>
        </tr>
      </tbody>
    </table>
    <el-dialog title="用户人员报名明细" :visible.sync="ExamineState" width="60%">
      <el-form :model="ExamineForm" ref="ExamineForm" label-width="100px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" prop="username">
              <el-input style="border: none" readonly v-model="ExamineForm.username"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="realname">
              <el-input readonly v-model="ExamineForm.realname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="昵称" prop="nickname">
              <el-input readonly v-model="ExamineForm.nickname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学号" prop="nickname">
              <el-input readonly v-model="ExamineForm.student_num"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学校" prop="nickname">
              <el-input readonly v-model="ExamineForm.school_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="专业" prop="nickname">
              <el-input readonly v-model="ExamineForm.specialty"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年级" prop="nickname">
              <el-input readonly v-model="ExamineForm.grade"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学历" prop="nickname">
              <el-input readonly v-model="ExamineForm.education_background"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="指导老师" prop="nickname">
              <el-input readonly v-model="ExamineForm.faculty_adviser"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="nickname">
              <el-input readonly v-model="ExamineForm.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="审核失败原因" prop="reason">
              <!-- <el-radio-group v-model="reason">
                <el-radio label="">请补充学生证图片</el-radio>
                <el-radio label="请完善个人信息">请完善个人信息</el-radio>
                <el-radio label="其他">其他</el-radio>
              </el-radio-group> -->
              <el-select style="width: 100%;" v-model="reason" placeholder="请选择">
                <el-option v-for="item in reasonList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div style="
                width: 100px;
                text-align: right;
                vertical-align: middle;
                float: left;
                font-size: 14px;
                color: #606266;
                line-height: 40px;
                padding: 0 12px 0 0;
                font-weight: 700;
                box-sizing: border-box;
              ">
              学生证图片
            </div>
            <el-image v-if="ExamineForm.student_card_path" style="width: 300px; height: 200px"
              :src="ExamineForm.student_card_path" :preview-src-list="srcList">
            </el-image>
            <el-empty v-else description="未上传学生证"></el-empty>
          </el-col>
          <el-col :span="12">
            <div style="
                width: 100px;
                text-align: right;
                vertical-align: middle;
                float: left;
                font-size: 14px;
                color: #606266;
                line-height: 40px;
                padding: 0 12px 0 0;
                font-weight: 700;
                box-sizing: border-box;
              ">
              其他图片
            </div>
            <el-image v-if="ExamineForm.other_img" style="width: 300px; height: 200px"
              :src="ExamineForm.other_img" :preview-src-list="othersrcList">
            </el-image>
            <el-empty v-else description="未上传其他图片"></el-empty>
          </el-col>
        </el-row>
        <el-row>
          <el-col> </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="CancelExamine">取 消</el-button>
        <el-button type="danger" @click="ConfirmExamine(0)">审核不通过</el-button>
        <el-button type="primary" @click="ConfirmExamine(1)">审核通过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Notification } from "element-ui";
export default {
  name: "Examines",
  data() {
    return {
      ExamineState: false,
      ExamineForm: {},
      reason: null, //审核原因
      srcList: [
      ],
      othersrcList: [
      ],
      reasonList: [
        '请补充学生证图片信息',
        '请完善其他图片信息',
        '请完善个人信息',
        '学历信息有误',
        '学校信息有误',
        '其他'
      ],
      activeName: 'BeReviewed'
    };
  },
  props: {
    ExamineDataList: {
      type: Array,
      default: null,
    },
    ExamineMatchList: {
      type: Array,
      default: null,
    },
    ExamineParams: {
      type: Object,
      default: null,
    },
    ReviewedIndex: {
      type: Number,
      default: 1,
    }
  },
  methods: {
    DownloadApprovedList() {
      this.$emit("DownloadApprovedList");
    },
    // 搜索审核列表
    ExamineSearch() {
      if (this.ExamineParams.challenge_id) {
        this.$emit("getExamineDataList", this.ExamineParams);
      } else {
        this.$message({
          message: "请选择指定比赛审核人员列表",
          type: "warning",
        });
      }
    },
    // 审核详情
    ExamineDetails(item) {
      this.ExamineForm = item;
      this.srcList.push(item.student_card_path)
      this.othersrcList.push(item.other_img)
      this.ExamineState = true;
    },
    // 取消审核
    CancelExamine() {
      this.ExamineForm = {};
      this.ExamineState = false;
      this.reason = null;
    },
    // 确认审核
    ConfirmExamine(n) {
      var ParamData = {};
      if (n == 0) {
        if (this.reason) {
          ParamData = {
            tourney_id: this.ExamineForm.tourney_id,
            passed: 0,
            reason: this.reason,
          };
          this.$emit("SubmitExamine", ParamData);
        } else {
          Notification.error({
            title: "必须选择审核不通过原因！",
          });
        }
      } else {
        ParamData = {
          tourney_id: this.ExamineForm.tourney_id,
          passed: 1,
        };
        this.$emit("SubmitExamine", ParamData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Examine {
  table {
    font-size: 14px;
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34, 36, 38, 0.15);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0.28571429rem;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    border-collapse: separate;
    border-spacing: 0;

    thead {
      box-shadow: none;

      tr {
        th {
          cursor: auto;
          background: #f9fafb;
          text-align: inherit;
          color: rgba(0, 0, 0, 0.87);
          padding: 0.92857143em 0.78571429em;
          vertical-align: inherit;
          font-style: none;
          font-weight: 700;
          text-transform: none;
          border-bottom: 1px solid rgba(34, 36, 38, 0.1);
          border-left: 1px solid rgba(34, 36, 38, 0.1);
        }

        th:last-child {
          width: 50px;
        }
      }
    }

    tbody {
      tr {
        font-size: 13px;

        td {
          padding: 0.78571429em 0.78571429em;
          text-align: inherit;
          border-left: 1px solid rgba(34, 36, 38, 0.1);
          border-bottom: 1px solid rgba(34, 36, 38, 0.1);
        }

        td:last-child {
          width: 50px;
        }
      }
    }
  }
}
</style>