import editor from "./editor.vue";
import axios from "axios";
import {
    getToken,
    getManagementStatus,
    getEntryPermissions,
    getlevel_info,
} from "@/utils/auth";
import {
    publicbaseURLs
} from "@/utils/request";
import {
    searchCompetitionDocuments
} from "../../api/SingleItem.js";
import {
    searchStudent,
    searchMatchList,
    delete_user,
    admin_register,
    admin_update_user,
    MatchList_delete,
    MatchList_update,
    match_delete_file,
    file_upload,
    addMatchList,
    reset_passwords,
    reset_score,
    search_all_permission,
    search_role,
    delete_role,
    add_role,
    update_role,
    search_apply_list,
    check_apply,
    user_code,
    delete_code,
    search_answer,
    search_admin,
    registration_list,
    download_registration_list,
    space_list_admin,
    enter_space,
    check_rate_type
} from "../../api/ManagementCenter.js";
import {
    match_search,
} from "../../api/SingleItem.js";
import Role from "./role.vue";
import Examine from "./Examine.vue";
import Answer from "./answer.vue"
import CodeDuplicationCheck from "./CodeDuplicationCheck.vue";
export default {
    name: "ManagementCenter",
    components: {
        editor,
        Role,
        Examine,
        Answer,
        CodeDuplicationCheck,
    },
    data() {
        return {
            sysAppIds: "",
            wrong_data: [], //错误信息
            wrong_title: "", //错误标题
            wrong_Show_state: false, //错误弹框显示状态
            EntryPermissionsState: [], //入口权限
            // ***********角色******************
            roleTotal: 0,
            roleParams: {
                pageNum: 1,
                pageSize: 10,
            },
            roleDataList: [],
            jurisdicDataList: [],
            UserRoleDataList: [],
            // ***********角色******************
            // ***********审核******************
            ExamineTotal: 0,
            ExamineParams: {
                keywords: "",
                pageNum: 1,
                pageSize: 10,
                challenge_id: null,
            },
            ExamineMatchList: [], //审核比赛列表
            ExamineDataList: [],
            ReviewedIndex: 1, //1——待审核名单，2——已审核名单
            // ***********审核******************

            /*********************************/

            UserCodeList: [], //文件列表
            UserCodeTotal: 0,
            UserCodeParams: {
                keywords: "",
                pageNum: 1,
                pageSize: 10,
                challenge_id: null,
            },
            /*********************************/

            challenge_id: null, //比赛ID
            level_info: null, //权限列表
            Files: [], //文件
            UrlApi: publicbaseURLs() + "/bulk_registration",
            MatchUrlApi: publicbaseURLs() + "/image_upload",
            UpdateMatchUrlApi: publicbaseURLs() + "/update_match_image",
            MatchFileList: [], //比赛图片文件列表
            UpdataMatchFileList: [], //修改比赛图片文件列表
            MaterialfileList: [], //已上传的文件材料
            EntranceChangeState: 0,
            UserListShowState: false, //用户列表展示状态
            AddUserShowState: false, //添加用户弹窗状态
            UpdateUserShowState: 0, //修改用户状态  0 用户列表  1 单个用户修改页面
            MatchListShowState: false, //比赛列表展示状态
            AddMatchShowState: false, //添加比赛弹窗状态
            UpdateMatchShowState: 0, //修改比赛状态  0 比赛列表  1 单个比赛修改页面
            UploadedMaterialState: false, //单个比赛已提交材料弹窗状态
            UploadedMaterialTableData: [
                //已提交材料表格数据列表
                {
                    date: "recalculation.sh",
                    name: "2021-08-20 10:09:32",
                },
                {
                    date: "y_t2.xlsx",
                    name: "2021-08-20 10:09:32",
                },
                {
                    date: "y_test.xlsx",
                    name: "2021-08-20 10:09:32",
                },
            ],
            UploadedMaterialData:{},//选中需要提交比赛材料信息
            UserList: [], //用户列表
            UserParams: {
                keywords: "",
                pageNum: 1,
                pageSize: 10,
            },
            IsShowAdmin: false,
            AdminList: [], //管理员列表
            Admintotal: 0,
            AdminParams: {
                pageNum: 1,
                pageSize: 10,
            },
            ViewStudentIDUrl: null, //学生证文件地址
            ViewStudentIDState: false, //查看学生证弹窗状态
            UserTotal: 0,
            MatchList: [], //比赛列表
            MatchParams: {
                keywords: "",
                pageNum: 1,
                pageSize: 10,
            },
            MatchTotal: 0,
            // 添加用户表单
            UserForm: {
                name: null,
                StudentNumber: null,
                phone: null,
                password: null,
            },
            // 修改用户表单
            UpdataUserFrom: {},
            // 用户表单验证
            UserRules: {
                username: [
                    {
                        required: true,
                        message: "请输入用户手机号",
                        trigger: "blur",
                    },
                ],
                nickname: [
                    {
                        required: true,
                        message: "请输入用户昵称",
                        trigger: "blur",
                    },
                ],
                realname: [
                    {
                        required: true,
                        message: "请输入用户姓名",
                        trigger: "blur",
                    },
                ],
                student_num: [
                    {
                        required: true,
                        message: "请输入用户学号",
                        trigger: "blur",
                    },
                ],
                school_name: [
                    {
                        required: true,
                        message: "请输入用户学校",
                        trigger: "blur",
                    },
                ],
                specialty: [
                    {
                        required: true,
                        message: "请输入专业",
                        trigger: "blur",
                    },
                ],
                grade: [
                    {
                        required: true,
                        message: "请选择年级",
                        trigger: "blur",
                    },
                ],
                education_background: [
                    {
                        required: true,
                        message: "请选择学历",
                        trigger: "blur",
                    },
                ],
                faculty_adviser: [
                    {
                        required: true,
                        message: "请输入指导老师",
                        trigger: "blur",
                    },
                ],
                email: [
                    {
                        required: true,
                        message: "请输入真实邮箱",
                        trigger: "blur",
                    },
                    {
                        pattern:
                            /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
                        message: "邮箱格式错误",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
            },
            // 用户修改表单验证
            UpdataUserRules: {
                username: [
                    {
                        required: true,
                        message: "请输入用户手机号",
                        trigger: "blur",
                    },
                ],
                nickname: [
                    {
                        required: true,
                        message: "请输入用户昵称",
                        trigger: "blur",
                    },
                ],
                realname: [
                    {
                        required: true,
                        message: "请输入用户姓名",
                        trigger: "blur",
                    },
                ],
                student_num: [
                    {
                        required: true,
                        message: "请输入用户学号",
                        trigger: "blur",
                    },
                ],
                school_name: [
                    {
                        required: true,
                        message: "请输入用户学校",
                        trigger: "blur",
                    },
                ],
                faculty_adviser: [
                    {
                        required: true,
                        message: "请输入指导老师",
                        trigger: "blur",
                    },
                ],
                specialty: [
                    {
                        required: true,
                        message: "请输入专业",
                        trigger: "blur",
                    },
                ],
                grade: [
                    {
                        required: true,
                        message: "请选择年级",
                        trigger: "blur",
                    },
                ],
                education_background: [
                    {
                        required: true,
                        message: "请选择学历",
                        trigger: "blur",
                    },
                ],
                email: [],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                    {
                        min: 6,
                        max: 18,
                        message: "长度在 6 到 18 个字符",
                        trigger: "blur",
                    },
                ],
                level_info: [
                    {
                        required: true,
                        message: "请选择用户等级",
                        trigger: "blur",
                    },
                ],
                status: [
                    {
                        required: true,
                        message: "请选择用户状态",
                        trigger: "blur",
                    },
                ],
                is_show: [
                    {
                        required: true,
                        message: "请选择用户分数显示状态",
                        trigger: "blur",
                    },
                ],
            },
            // 添加比赛表单
            MatchFrom: {},
            // 修改比赛
            UpdataMatchFrom: {},
            // 比赛表单验证
            MatchRules: {
                challenge_title: [
                    {
                        required: true,
                        message: "请输入比赛名称",
                        trigger: "blur",
                    },
                ],
                is_check: [
                    {
                        required: true,
                        message: "请选择报名是否需要审核",
                        trigger: "change",
                    },
                ],
                challenge_detail: [
                    {
                        required: true,
                        message: "请输入比赛简介",
                        trigger: "blur",
                    },
                ],
                // challenge_image_path: [{
                //     required: true,
                //     message: "请选择比赛图片",
                //     trigger: "change",
                // }, ],
                registration_time: [
                    {
                        required: true,
                        message: "请选择比赛报名时间",
                        trigger: "change",
                    },
                ],
                start_time: [
                    {
                        required: true,
                        message: "请选择比赛起始时间",
                        trigger: "change",
                    },
                ],
                en_time: [
                    {
                        required: true,
                        message: "请选择比赛终止时间",
                        trigger: "change",
                    },
                ],
                result_type: [
                    {
                        required: true,
                        message: "请输入上传文件扩展名限制",
                        trigger: "change",
                    },
                ],
                file_size: [
                    {
                        required: true,
                        message: "请输入上传文件大小限制",
                        trigger: "blur",
                    },
                ],
                submit_num: [
                    {
                        required: true,
                        message: "请输入比赛每天提交次数",
                        trigger: "blur",
                    },
                ],
                formula_mode: [
                    {
                        required: true,
                        message: "请输入分数类型",
                        trigger: "blur",
                    },
                ],
                check_type: [
                    {
                        required: true,
                        message: "请选择查重方案",
                        trigger: "change",
                    },
                ],
                challenge_status: [
                    {
                        required: true,
                        message: "请选择比赛状态",
                        trigger: "change",
                    },
                ],
            },
            // 修改比赛表单验证
            UpdataMatchRules: {
                CompetitionCode: [
                    {
                        required: true,
                        message: "请输入比赛编码",
                        trigger: "blur",
                    },
                    {
                        min: 3,
                        max: 20,
                        message: "长度在 3 到 20 个字符",
                        trigger: "blur",
                    },
                ],
            },
            // 上传文件扩展名限制数组
            FileExtensionList: [
                {
                    label: "csv",
                    value: "csv",
                },
                {
                    label: "xlsx",
                    value: "xlsx",
                },
            ],
            checkRateTypeList:[],//查重方案
            educationBackgroundList: [
                "高中及以下",
                "大专",
                "本科",
                "硕士研究生",
                "博士研究生",
            ],
            gradeList: [],
            srcList: [],
            // 比赛答案参数
            searchAnswerParms: {
                pageNum: 1,
                pageSize: 10,
            },
            // 比赛答案列表总数
            searchAnswertotal: 0,
            // 比赛答案列表
            searchAnswerList: [],

            ExperimentalSpacePopUpState:false,//查看共享空间弹窗状态
            // 查看共享空间参数
            ExperimentalSpaceParams:{
                "keywords": "", 
                "pageNum": 1, 
                "pageSize": 10, 
                "match_id": null, 
            },
            ExperimentalSpaceMatchList:[],//共享空间比赛列表
            SpacesList:[],//共享空间列表
            SpacesTotal:0,//共享空间列表数
        };
    },
    created() {},
    mounted() {
        this.Getcheck_RateTypeList()
        this.EntryPermissionsState =
            getEntryPermissions()?.split(",") ||
            this.$store.state.user.EntryPermissions;
        this.level_info =
            getlevel_info() || this.$store.state.user.Store_level_info;
        this.$nextTick(() => {
            if (this.EntryPermissionsState.length > 0) {
                switch (Number(this.EntryPermissionsState[0])) {
                    case 0:
                        // 默认选择用户列表
                        this.OclickEntrance(0);
                        break;
                    case 1:
                        // 默认选择用户列表
                        this.OclickEntrance(1);
                        break;
                    case 2:
                        // 默认选择用户列表
                        this.OclickEntrance(2);
                        break;
                    case 3:
                        // 默认选择用户列表
                        this.OclickEntrance(3);
                        break;
                    case 4:
                        // 默认选择文件列表
                        this.OclickEntrance(4);
                        break;
                    case 5:
                        // 默认选择文件列表
                        this.OclickEntrance(5);
                        break;
                    case 10:
                        // 默认选择用户列表
                        this.OclickEntrance(10);
                        break;
                    case 11:
                        // 默认选择用户列表
                        this.OclickEntrance(11);
                        break;
                    case 12:
                        // 默认选择用户列表
                        this.OclickEntrance(12);
                        break;
                    case 13:
                        // 默认选择用户列表
                        this.OclickEntrance(13);
                        break;
                    default:
                        return;
                }
            }
        });
    },
    watch: {
        "$store.state.user.EntryPermissions"(val) {
            if (val.length > 0) {
                this.EntryPermissionsState = val;
                if (this.EntryPermissionsState.length > 0) {
                    switch (Number(this.EntryPermissionsState[0])) {
                        case 0:
                            // 默认选择用户列表
                            this.OclickEntrance(0);
                            break;
                        case 1:
                            // 默认选择用户列表
                            this.OclickEntrance(1);
                            break;
                        case 2:
                            // 默认选择用户列表
                            this.OclickEntrance(2);
                            break;
                        case 3:
                            // 默认选择用户列表
                            this.OclickEntrance(3);
                            break;
                        case 4:
                            // 默认选择文件列表
                            this.OclickEntrance(4);
                            break;
                        case 10:
                            // 默认选择用户列表
                            this.OclickEntrance(10);
                            break;
                        case 11:
                            // 默认选择用户列表
                            this.OclickEntrance(11);
                            break;
                        case 12:
                            // 默认选择用户列表
                            this.OclickEntrance(12);
                            break;
                        case 13:
                            // 默认选择用户列表
                            this.OclickEntrance(13);
                            break;
                        default:
                            return;
                    }
                }
            }
        },
    },
    methods: {
        Getcheck_RateTypeList(){
            check_rate_type().then(res=>{
                this.checkRateTypeList = res.check_list
            })
        },
        // 进入空间
        EnteringSpace(item){
            this.$store.commit("app/CHANGE_LOADING", true);
            enter_space({
                "username": item.username, 
                "match_id": item.match_id
            }).then(res=>{
                if(res.code == 200){
                    this.$store.commit("app/CHANGE_LOADING", false);
                    window.open(res.url);
                }
            }).catch(err=>{
                this.$message.error('进入空间失败');
                this.$store.commit("app/CHANGE_LOADING", false);
            })
        },
        // 触发查看共享空间弹窗
        ViewExperimentalSpace(username){
            this.ExperimentalSpaceParams.keywords = username
            searchMatchList({
                keywords: "",
                pageNum: 1,
                pageSize: 50,
            }).then((res) => {
                this.ExperimentalSpaceMatchList = res.data.list;
                this.ExperimentalSpaceParams.match_id = res.data.list[0].challenge_id
                this.ExperimentalSpacePopUpState = true
                this.GetSpaceCompetition()
            });
        },
        // 获取共享空间
        GetSpaceCompetition(){
            space_list_admin(this.ExperimentalSpaceParams).then(res=>{
                this.SpacesList = res.data.list;
                this.SpacesTotal = res.data.total;
            })
        },
        // 获取已上传比赛答案列表
        GetsearchAnswer() {
            search_answer(this.searchAnswerParms).then((res) => {
                if (res.code == 200) {
                    this.searchAnswerList = res.data.list;
                    this.searchAnswertotal = res.data.total;
                }
            });
        },
        // 下载比赛代码
        getdatarDownloadCompetitionCode(id, pageNum, pageSize, paging) {
            const postparams = {
                keywords: "",
                challenge_id: id,
                pageNum: pageNum,
                pageSize: pageSize,
                paging: paging,
            };
            axios({
                // responseType: 'blob'
                url: publicbaseURLs() + "/search/download_user_code",
                method: "post",
                data: postparams,
                responseType: "blob",
                headers: {
                    Authorization: getToken(),
                    // "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                //这里res.data是返回的blob对象,即对应的二进制流
                let blob = res.data;
                let downloadElement = document.createElement("a");
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                let fileName = res.headers["content-disposition"]
                    ? res.headers["content-disposition"]
                          .split(";")[1]
                          .split("=")[1]
                    : new Date().getTime() + ".xlsx";

                downloadElement.href = href;
                downloadElement.download = decodeURIComponent(fileName); //解码
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href); //释放掉blob对象
            });
        },
        // 下载比赛代码列表
        DownloadCodeList(item) {
            const postparams = {
                challenge_id: item.challenge_id,
                keywords: "",
                pageNum: "1",
                pageSize: "10",
                paging: 0,
            };
            axios({
                // responseType: 'blob'
                url: publicbaseURLs() + "/search/download_user_code",
                method: "post",
                data: postparams,
                responseType: "blob",
                headers: {
                    Authorization: getToken(),
                    // "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                //这里res.data是返回的blob对象,即对应的二进制流
                let blob = res.data;
                let downloadElement = document.createElement("a");
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                let fileName = res.headers["content-disposition"]
                    ? res.headers["content-disposition"]
                          .split(";")[1]
                          .split("=")[1]
                    : new Date().getTime() + ".xlsx";

                downloadElement.href = href;
                downloadElement.download = decodeURIComponent(fileName); //解码
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href); //释放掉blob对象
            });
        },
        // 查看用户学生证
        ViewStudentID(Url) {
            if (!Url) {
                this.$message({
                    type: "error",
                    message: "该用户暂无上传学生证！",
                });
                return;
            }
            this.ViewStudentIDUrl = Url;
            this.srcList.push(Url);
            if (this.ViewStudentIDUrl) {
                this.ViewStudentIDState = true;
            }
        },
        // 下载比赛源码文件
        SingleitemDow(URL, file_name) {
            axios({
                url: URL,
                method: "get",
                responseType: "blob",
                headers: {
                    Authorization: getToken(),
                },
            }).then((res) => {
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement("a");
                link.style.display = "none";
                link.href = url;
                link.setAttribute("download", file_name); // 自定义下载文件名（如exemple.txt）
                document.body.appendChild(link);
                link.click();
            });
        },
        // 删除比赛源码文件
        SingleDalete(item) {
            this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delete_code(item.file_id)
                        .then((res) => {
                            if (res.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: res.msg,
                                });
                                this.GetUserCodeList();
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.msg,
                                });
                                this.GetUserCodeList();
                            }
                        })
                        .catch((err) => {
                            this.$message({
                                type: "error",
                                message: err.msg,
                            });
                            this.GetUserCodeList();
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 获取比赛文件数据列表
        GetUserCodeList() {
            let Params = this.UserCodeParams;
            user_code(Params)
                .then((res) => {
                    if (res.code == 200) {
                        this.UserCodeList = res.data.list;
                        this.UserCodeTotal = res.data.total;
                    }
                })
                .catch(() => {
                    this.UserCodeList = [];
                });
        },
        // 翻译用户角色为中文
        TranslationRole(id) {
            let CopeDataList = JSON.parse(
                JSON.stringify(this.UserRoleDataList)
            );
            CopeDataList.push({
                role_id: 1,
                rolename: "超级管理员",
            });
            let RoleName = "";
            CopeDataList.forEach((item) => {
                if (item.role_id == id) {
                    RoleName = item.rolename;
                }
            });
            return RoleName;
        },
        // 提交审核
        SubmitExamine(item) {
            check_apply(item).then((res) => {
                if (res.code == 200) {
                    this.getExamineDataList();
                    this.$refs.ExamineID.CancelExamine();
                    this.$message({
                        type: "success",
                        message: res.msg,
                    });
                }
            });
        },
        // 下载已审核人员
        DownloadApprovedList() {
            let postparams = {
                challenge_id: this.ExamineParams.challenge_id,
                keywords: "",
                pageNum: "1",
                pageSize: "10",
                paging: 0,
            };
            // alert(this.ExamineParams.challenge_id)
            axios({
                // responseType: 'blob'
                url: publicbaseURLs() + "/search/download_registration_list",
                method: "post",
                data: postparams,
                responseType: "blob",
                headers: {
                    Authorization: getToken(),
                    // "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                //这里res.data是返回的blob对象,即对应的二进制流
                let blob = res.data;
                let downloadElement = document.createElement("a");
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                let fileName = res.headers["content-disposition"]
                    ? res.headers["content-disposition"]
                          .split(";")[1]
                          .split("=")[1]
                    : new Date().getTime() + ".xlsx";

                downloadElement.href = href;
                downloadElement.download = decodeURIComponent(fileName); //解码
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href); //释放掉blob对象
            });
        },
        // 切换审核功能列表
        ChangeReviewedIndex(index) {
            this.ReviewedIndex = index;
            this.ExamineParams.keywords = "";
            this.getExamineDataList();
        },
        // 获取审核列表数据
        getExamineDataList() {
            let Params = this.ExamineParams;
            let ReviewedParams = JSON.parse(JSON.stringify(this.ExamineParams));
            ReviewedParams.paging = 1;
            if (this.ReviewedIndex == 1) {
                search_apply_list(Params)
                    .then((res) => {
                        if (res.code == 200) {
                            this.ExamineDataList = res.data.list;
                            this.ExamineTotal = res.data.total;
                        }
                    })
                    .catch(() => {
                        this.ExamineDataList = [];
                    });
            } else {
                registration_list(ReviewedParams)
                    .then((res) => {
                        if (res.code == 200) {
                            this.ExamineDataList = res.data.list;
                            this.ExamineTotal = res.data.total;
                        }
                    })
                    .catch(() => {
                        this.ExamineDataList = [];
                    });
            }
        },
        // 获取角色列表数据
        getroleDataList() {
            search_role()
                .then((res) => {
                    if (res.code == 200) {
                        this.roleDataList = res.info_list;
                    }
                })
                .catch(() => {
                    this.roleDataList = [];
                });
        },
        // 新增角色
        AddRole(item) {
            add_role(item)
                .then((res) => {
                    if (res.code == 200) {
                        // 获取角色列表
                        this.getroleDataList();
                        this.$refs.RoleId.EmptyRoleFoem();
                        this.$message({
                            type: "success",
                            message: res.msg,
                        });
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "新增失败",
                    });
                    this.$refs.RoleId.EmptyRoleFoem();
                });
        },
        // update_role
        // 修改角色列表
        UpadteRole(item) {
            update_role(item)
                .then((res) => {
                    if (res.code == 200) {
                        // 获取角色列表
                        this.getroleDataList();
                        this.$refs.RoleId.EmptyRoleFoem();
                        this.$message({
                            type: "success",
                            message: res.msg,
                        });
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "修改失败",
                    });
                    this.$refs.RoleId.EmptyRoleFoem();
                });
        },
        // 删除角色
        RoleDalete(item) {
            delete_role({
                role_id: item.role_id,
            }).then((res) => {
                if (res.code == 200) {
                    // 获取角色列表
                    this.getroleDataList();
                    this.$message({
                        type: "success",
                        message: res.msg,
                    });
                }
            });
        },
        // 获取权限列表数据
        getjurisdictionDataList() {},
        // 编辑年级列表
        getNf() {
            var nfOptionsArray = new Array();
            var years = new Date().getFullYear();
            for (let i = years - 9; i <= years; i++) {
                var anOption = {};
                anOption.dictValue = i + "";
                anOption.dictLabel = i + "";
                nfOptionsArray.push(anOption);
            }
            this.gradeList = nfOptionsArray;
        },
        // 获取用户列表
        getsearchStudent() {
            searchStudent(this.UserParams).then((res) => {
                this.UserList = res.data.list;
                this.UserTotal = res.data.total;
            });
        },
        // 查看所有管理员
        SeeAdmin() {
            this.getsearchAdmin();
            this.IsShowAdmin = true;
        },
        // 获取管理员列表
        getsearchAdmin() {
            search_admin(this.AdminParams).then((res) => {
                this.AdminList = res.data.list;
                this.Admintotal = res.data.total;
            });
        },
        // 获取比赛列表
        getsearchMatchList() {
            searchMatchList(this.MatchParams).then((res) => {
                this.MatchList = res.data.list;
                this.MatchTotal = res.data.total;
            });
        },
        // 新增比赛网站图片预览
        handlePictureCardPreview() {},
        // 删除比赛网站图片
        handleRemove(file, fileList) {
            this.MatchFileList = fileList;
        },
        // 存储比赛网站图片文件
        MatchhandleChange(flie, flieList) {
            if (flieList.length > 1) {
                // this.handleChange(flieList);
                this.MatchFileList = [flieList[1]];
            } else {
                this.MatchFileList = flieList;
            }
        },
        // 新增用户文件选取
        handleChange(row) {
            this.Files = row.raw;
        },
        // 修改比赛网站图片预览
        UpdatahandlePictureCardPreview() {},
        // 文件移除
        UpdatahandleRemove(file, fileList) {
            this.UpdataMatchFileList = fileList;
        },
        // 上传比赛文件选取
        UpdataMatchhandleChange(flie, flieList) {
            if (flieList.length > 1) {
                // this.handleChange(flieList);
                this.UpdataMatchFileList = [flieList[1]];
            } else {
                this.UpdataMatchFileList = flieList;
            }
        },
        // 复制成功
        onCopy() {
            this.wrong_Show_state = false;
            this.$message({
                message: "成功复制到剪贴板",
                type: "success",
            });
        },
        // 复制失败
        onError() {
            this.$message({
                message: "操作失败",
                type: "error",
            });
        },
        // 用户批量上次成功
        handlesuccess() {
            this.$store.commit("app/CHANGE_LOADING", true);
            var data = new FormData();
            data.append("file", this.Files);
            data.append("token", getToken());
            axios({
                url: this.UrlApi,
                method: "post",
                data: data,
                headers: {
                    Authorization: getToken(),
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: "success",
                            message: "上传成功",
                        });
                        this.$store.commit("app/CHANGE_LOADING", false);
                        this.Files = [];
                    } else if (res.data.code == 10024) {
                        this.$message({
                            type: "error",
                            message: res.data.msg,
                        });
                        this.wrong_Show_state = true;
                        this.wrong_title = res.data.msg;
                        this.wrong_data = res.data.wrong_data;
                        this.sysAppIds = res.data.wrong_data.join(",");
                        this.$store.commit("app/CHANGE_LOADING", false);
                        this.Files = [];
                    } else if (res.data.code == 10072) {
                        this.$message({
                            type: "error",
                            message: res.data.msg,
                        });
                        this.wrong_Show_state = true;
                        this.wrong_title = res.data.msg;
                        this.wrong_data = res.data.wrong_data;
                        this.sysAppIds = res.data.wrong_data.join(",");
                        this.$store.commit("app/CHANGE_LOADING", false);
                        this.Files = [];
                    } else if (res.data.code == 10071) {
                        this.$message({
                            type: "error",
                            message: res.data.msg,
                        });
                        this.$store.commit("app/CHANGE_LOADING", false);
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "上传失败",
                    });
                    this.$store.commit("app/CHANGE_LOADING", false);
                    this.Files = [];
                });
        },
        // 用户批量上次失败
        handleError() {
            this.$message({
                type: "error",
                message: "上传失败",
            });
            this.Files = [];
        },
        // 获取权限角色列表
        GetSearchAllPermission() {
            search_all_permission()
                .then((res) => {
                    if (res.code == 200) {
                        this.jurisdicDataList = res.data;
                    }
                })
                .catch(() => {
                    this.jurisdicDataList = [];
                });
        },
        // 左侧切换导航栏
        OclickEntrance(item) {
            this.wrong_Show_state = false;
            if (item == 0) {
                this.getsearchStudent();
                this.EntranceChangeState = item;
                // 默认获取角色列表
                search_role()
                    .then((res) => {
                        if (res.code == 200) {
                            this.UserRoleDataList = res.info_list;
                            this.UserRoleDataList.push({
                                role_id: 2,
                                rolename: "普通用户",
                            });
                        }
                    })
                    .catch(() => {
                        this.UserRoleDataList = [];
                    });
                this.$forceUpdate();
            } else if (item == 1) {
                this.getNf();
                // this.UpdateUserShowState = 0;
                this.EntranceChangeState = item;
                this.$forceUpdate();
            } else if (item == 2) {
                this.getsearchMatchList();
                this.EntranceChangeState = item;
                this.$forceUpdate();
            } else if (item == 3) {
                // this.UpdateMatchShowState = 0;
                this.EntranceChangeState = item;
                this.$forceUpdate();
            } else if (item == 4) {
                searchMatchList(this.MatchParams).then((res) => {
                    if (res.code == 200) {
                        if (res.data.total > 0) {
                            this.UserCodeParams = {
                                keywords: "",
                                pageNum: 1,
                                pageSize: 10,
                                challenge_id: res.data.list[0].challenge_id,
                            };
                            this.GetUserCodeList();
                            this.ExamineMatchList = res.data.list;
                            this.$forceUpdate();
                        }
                    }
                });
                this.EntranceChangeState = item;
            } else if (item == 5) {
                this.EntranceChangeState = item;
            } else if (item == 10) {
                this.EntranceChangeState = item;
                // 获取角色列表
                this.getroleDataList();
                // 获取权限列表
                this.GetSearchAllPermission();
            } else if (item == 11) {
                // 默认审核选项——待审核名单
                this.ReviewedIndex = 1;
                searchMatchList(this.MatchParams).then((res) => {
                    if (res.code == 200) {
                        if (res.data.total > 0) {
                            this.ExamineParams = {
                                keywords: "",
                                pageNum: 1,
                                pageSize: 10,
                                challenge_id: res.data.list[0].challenge_id,
                            };
                            this.getExamineDataList();
                            this.ExamineMatchList = res.data.list;
                            this.EntranceChangeState = item;
                            this.$forceUpdate();
                        }
                    }
                });
            } else if (item == 12) {
                this.GetsearchAnswer();
                this.EntranceChangeState = item;
                this.$forceUpdate();
            }else if (item == 13) {
                this.EntranceChangeState = item;
                searchMatchList({
                    keywords: "",
                    pageNum: 1,
                    pageSize: 50,
                }).then((res) => {
                    this.ExperimentalSpaceMatchList = res.data.list;
                    this.ExperimentalSpaceParams.match_id = res.data.list[0].challenge_id
                    this.GetSpaceCompetition()
                });
            } else {
                this.getsearchStudent();
                this.EntranceChangeState = 0;
                this.$forceUpdate();
            }
        },
        //已上传材料列表事件-------------------
        // 材料下载
        MaterialDownload(Url) {
            window.location.href = Url;
            return;
        },
        // 材料删除
        MaterialDelete(item) {
            this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    match_delete_file({
                        file_id: item.file_id,
                    }).then((res) => {
                        if (res.code == 200) {
                            this.UploadedMaterial(item);
                            this.$message({
                                type: "success",
                                message: res.msg,
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 存储微未上传的文件
        MaterialhandleChange(file, fileList) {
            this.MaterialfileList = fileList;
        },
        // 移除未上次的文件
        MaterialhandleRemove(file, fileList) {
            console.log(file, fileList);
        },
        // 提交未上次的文件
        submitUploadMaterial() {
            if (this.challenge_id && this.MaterialfileList.length > 0) {
                var FileData = new FormData();
                this.MaterialfileList.forEach((item) => {
                    if (item.raw) {
                        FileData.append("file", item.raw);
                    }
                });
                this.$store.commit("app/CHANGE_LOADING", true);
                file_upload(this.challenge_id, FileData)
                    .then((res) => {
                        if (res.code == 200) {
                            this.UploadedMaterial({
                                challenge_id: this.challenge_id,
                            });
                            this.$message({
                                type: "success",
                                message: "上传成功",
                            });
                            this.MaterialfileList = [];
                            FileData = null;
                            this.$store.commit("app/CHANGE_LOADING", false);
                        }
                    })
                    .catch(() => {
                        this.$message({
                            type: "error",
                            message: "上传失败",
                        });
                        this.MaterialfileList = [];
                        FileData = null;
                        this.$store.commit("app/CHANGE_LOADING", false);
                    });
            } else {
                this.UploadedMaterialState = false;
                this.MaterialfileList = [];
                this.$store.commit("app/CHANGE_LOADING", false);
            }
        },
        //已上传材料列表事件-------------------

        // 重新计算分数
        RecalculateScore(item) {
            this.$confirm("此操作将长时间等待重新计算分数, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$store.commit("app/CHANGE_LOADING", true);
                    reset_score({
                        challenge_id: item.challenge_id,
                    })
                        .then((res) => {
                            if (res.code == 200) {
                                this.$store.commit("app/CHANGE_LOADING", false);
                                this.$message({
                                    type: "success",
                                    message: "重新计算分数成功，请查看回馈!",
                                });
                                this.$notify({
                                    title: "备份总数",
                                    message: res.backup_total + "条",
                                    type: "success",
                                    duration: 0,
                                });
                                setTimeout(() => {
                                    this.$notify({
                                        title: "备份地址",
                                        message: res.backup_path,
                                        type: "success",
                                        duration: 0,
                                    });
                                }, 100);
                                setTimeout(() => {
                                    this.$notify({
                                        title: "成功条数",
                                        message: res.update_score_num + "条",
                                        type: "success",
                                        duration: 0,
                                    });
                                }, 100);
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.msg,
                                });
                                this.$store.commit("app/CHANGE_LOADING", false);
                            }
                        })
                        .catch(() => {
                            this.$store.commit("app/CHANGE_LOADING", false);
                        });
                })
                .catch(() => {
                    this.$store.commit("app/CHANGE_LOADING", false);
                    this.$message({
                        type: "info",
                        message: "已取消重新计算分数",
                    });
                });
        },
        // 单个比赛页面事件----------------------
        // 修改已上传文件材料弹窗页面状态
        UploadedMaterial(item) {
            this.challenge_id = item.challenge_id;
            this.UploadedMaterialTableData = [];
            searchCompetitionDocuments({
                challenge_id: item.challenge_id,
            })
                .then((res) => {
                    if (res.code == 200) {
                        this.UploadedMaterialTableData = res.data;
                        this.UploadedMaterialData = item
                        this.UploadedMaterialState = true;
                    }
                })
                .catch(() => {
                    this.UploadedMaterialState = true;
                });
        },
        // 单个比赛页面事件----------------------

        // 切换用户列表状态
        ChangeUserlist() {
            this.UserListShowState = true;
        },
        // 返回用户列表上一级
        ReturnUser() {
            this.UserListShowState = false;
        },
        // 切换比赛列表状态
        ChangeMatchlist() {
            this.MatchListShowState = true;
        },
        // 取消用户表单提交
        CancelAddUser() {
            this.UserForm = {};
            this.AddUserShowState = false;
        },
        // 提交用户表单
        SubmitUser() {
            this.$store.commit("app/CHANGE_LOADING", true);
            this.$refs["UserForm"].validate((valid) => {
                if (valid) {
                    admin_register({
                        register_dict: this.UserForm,
                    })
                        .then((res) => {
                            if (res.code == 200) {
                                this.UserForm = {};
                                this.$message({
                                    type: "success",
                                    message: "添加成功!",
                                });
                                this.getsearchStudent();
                                this.$store.commit("app/CHANGE_LOADING", false);
                            }
                        })
                        .catch(() => {
                            this.$store.commit("app/CHANGE_LOADING", false);
                        });
                } else {
                    this.$store.commit("app/CHANGE_LOADING", false);
                    return false;
                }
            });
        },
        // 刪除用戶列表
        UserDalete(item) {
            this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$store.commit("app/CHANGE_LOADING", true);
                    delete_user({
                        username: item.username,
                    })
                        .then((res) => {
                            if (res.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                                this.getsearchStudent();
                                this.$store.commit("app/CHANGE_LOADING", false);
                            } else {
                                this.$message({
                                    type: "info",
                                    message: res.msg,
                                });
                                this.$store.commit("app/CHANGE_LOADING", false);
                            }
                        })
                        .catch(() => {
                            this.$store.commit("app/CHANGE_LOADING", false);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                    this.$store.commit("app/CHANGE_LOADING", false);
                });
        },
        // 重置用户密码
        Reset(item) {
            this.$confirm(
                "此操作将该用户密码重置为“123”, 是否继续重置?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    reset_passwords({
                        username: item.username,
                    })
                        .then((res) => {
                            if (res.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: "密码重置成功!",
                                });
                            }
                        })
                        .catch(() => {
                            this.$message({
                                type: "error",
                                message: "重置密码失败",
                            });
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消重置密码",
                    });
                });
        },
        // 修改用户列表
        UserUpdate(item) {
            this.UpdataUserFrom = item;
            this.UpdataUserFrom.level_info = item.level_info + "";
            this.UpdataUserFrom.status = item.status + "";
            // this.UpdataUserFrom = null;
            this.UpdateUserShowState = 1;
        },
        // 取消修改用户
        CancelUpdataUser() {
            this.UpdataUserFrom = {};
            this.UpdateUserShowState = 0;
        },
        // 确认修改单个用户
        UpdataSubmitUser() {
            this.$store.commit("app/CHANGE_LOADING", true);
            this.$refs["UpdataUserFrom"].validate((valid) => {
                if (valid) {
                    admin_update_user({
                        update_info: this.UpdataUserFrom,
                    })
                        .then((res) => {
                            if (res.code == 200) {
                                this.getsearchStudent();
                                this.UpdateUserShowState = 0;
                                this.$store.commit("app/CHANGE_LOADING", false);
                            } else {
                                this.$store.commit("app/CHANGE_LOADING", false);
                            }
                        })
                        .catch(() => {
                            this.$store.commit("app/CHANGE_LOADING", false);
                        });
                } else {
                    this.$store.commit("app/CHANGE_LOADING", false);
                    return false;
                }
            });
        },
        // 添加比赛弹窗显示事件
        AddMatch() {
            this.MatchFrom = {};
            // 取消添加用户弹窗
            this.CancelAddUser();
            this.AddMatchShowState = true;
        },
        // 返回比赛列表
        ReturnMatch() {
            this.MatchListShowState = false;
        },
        // 取消比赛表单提交
        CancelAddMatch() {
            this.MatchFrom = {};
            this.AddMatchShowState = false;
        },
        // 提交比赛表单
        SubmitMatch() {
            if (this.MatchFileList.length != 1) {
                this.$message({
                    type: "warning",
                    message: "请选择比赛图片",
                });
                return;
            }
            this.$store.commit("app/CHANGE_LOADING", true);
            this.$refs["MatchFrom"].validate((valid) => {
                if (valid) {
                    var data = new FormData();
                    data.append("file", this.MatchFileList[0].raw);
                    axios({
                        url: this.MatchUrlApi,
                        method: "post",
                        data: data,
                        headers: {
                            Authorization: getToken(),
                            "Content-Type": "multipart/form-data",
                        },
                    })
                        .then((res) => {
                            if (res.data.code == 200) {
                                this.MatchFrom.challenge_image_path =
                                    res.data.img_url;
                                this.MatchFileList = [];
                                addMatchList({
                                    match_info: this.MatchFrom,
                                })
                                    .then((ress) => {
                                        if (ress.code == 200) {
                                            this.$message({
                                                type: "success",
                                                message: "添加比赛成功",
                                            });
                                            this.MatchFrom = [];
                                            this.MatchFileList = [];
                                            this.OclickEntrance(2);
                                            this.$store.commit(
                                                "app/CHANGE_LOADING",
                                                false
                                            );
                                        } else {
                                            this.$message({
                                                type: "error",
                                                message: ress.msg,
                                            });
                                            this.$store.commit(
                                                "app/CHANGE_LOADING",
                                                false
                                            );
                                        }
                                    })
                                    .catch(() => {
                                        this.$store.commit(
                                            "app/CHANGE_LOADING",
                                            false
                                        );
                                    });
                            } else {
                                this.$message({
                                    type: "error",
                                    message: "上传失败",
                                });
                                this.MatchFileList = [];
                                this.$store.commit("app/CHANGE_LOADING", false);
                            }
                        })
                        .catch(() => {
                            this.$message({
                                type: "error",
                                message: "上传失败",
                            });
                            this.MatchFileList = [];
                            this.$store.commit("app/CHANGE_LOADING", false);
                        });
                } else {
                    this.$store.commit("app/CHANGE_LOADING", false);
                    return false;
                }
            });
        },
        // 取消修改放比赛
        CancelUpdataMatch() {
            this.UpdateMatchShowState = 0;
        },
        // 提交修改单个比赛
        UpdataSubmitMatch() {
            this.$refs["UpdataMatchFrom"].validate((valid) => {
                if (valid) {
                    this.$store.commit("app/CHANGE_LOADING", true);
                    if (this.UpdataMatchFileList.length > 0) {
                        var data = new FormData();
                        data.append("file", this.UpdataMatchFileList[0].raw);
                        data.append("token", getToken());
                        axios({
                            url:
                                this.UpdateMatchUrlApi +
                                "/" +
                                this.UpdataMatchFrom.challenge_id,
                            method: "post",
                            data: data,
                            headers: {
                                Authorization: getToken(),
                                "Content-Type": "multipart/form-data",
                            },
                        })
                            .then((res) => {
                                if (res.data.code == 200) {
                                    let CopeUpdataMatchFrom = JSON.parse(
                                        JSON.stringify(this.UpdataMatchFrom)
                                    );
                                    CopeUpdataMatchFrom.challenge_image_path =
                                        res.data.image_url;
                                    MatchList_update({
                                        match_info: CopeUpdataMatchFrom,
                                    })
                                        .then((ress) => {
                                            if (ress.code == 200) {
                                                this.$message({
                                                    type: "success",
                                                    message: "修改成功!",
                                                });
                                                this.UpdateMatchShowState = 0;
                                                this.getsearchMatchList();
                                                this.$store.commit(
                                                    "app/CHANGE_LOADING",
                                                    false
                                                );
                                            }
                                        })
                                        .catch(() => {
                                            this.$message({
                                                type: "error",
                                                message: "修改比赛失败",
                                            });
                                            this.$store.commit(
                                                "app/CHANGE_LOADING",
                                                false
                                            );
                                        });
                                }
                            })
                            .catch(() => {
                                this.UpdataMatchFileList = [];
                                this.$message({
                                    type: "error",
                                    message: "上传失败",
                                });
                                this.$store.commit("app/CHANGE_LOADING", false);
                            });
                    } else {
                        MatchList_update({
                            match_info: this.UpdataMatchFrom,
                        })
                            .then((res) => {
                                if (res.code == 200) {
                                    this.$message({
                                        type: "success",
                                        message: "修改成功!",
                                    });
                                    this.UpdateMatchShowState = 0;
                                    this.getsearchMatchList();
                                    this.$store.commit(
                                        "app/CHANGE_LOADING",
                                        false
                                    );
                                }
                            })
                            .catch(() => {
                                this.$message({
                                    type: "error",
                                    message: "修改比赛失败",
                                });
                                this.$store.commit("app/CHANGE_LOADING", false);
                            });
                    }
                } else {
                    this.$store.commit("app/CHANGE_LOADING", false);
                    return false;
                }
            });
        },
        MatchUpdate(item) {
            this.$store.commit("app/CHANGE_LOADING", true);
            match_search({
                challenge_id: item.challenge_id,
            })
                .then((res) => {
                    this.UpdataMatchFrom = res;
                    this.UpdateMatchShowState = 1;
                    this.UpdataMatchFileList = [];
                    this.$store.commit("app/CHANGE_LOADING", false);
                })
                .catch((err) => {
                    this.$store.commit("app/CHANGE_LOADING", false);
                });
        },
        MatchDalete(item) {
            this.$confirm("此操作将永久删除该比赛, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    MatchList_delete({
                        challenge_id: item.challenge_id,
                    }).then((res) => {
                        if (res.code == 200) {
                            this.getsearchMatchList();
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 编写结果分析代码
        CodeWritingResults(item) {
            this.$router.push({
                path: "/CodeWritingResults",
                query: {
                    id: item,
                },
            });
        },
    },
};
